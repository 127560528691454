import React from 'react';
import { serverSideTranslation } from '../util/next';
import Page from '../components/Layout/Page';
import VideoTopBanner from '../modules/VideoTopBanner';
import TechnologyConetnt, { type DataType } from '../modules/TechnologyConetnt';
import NewsFooter from '../modules/NewsFooter';
import { getPageContent } from '../api/pagesApi';

import { genShareConfig } from '../components/Share/use-config-share';

interface Props {
  pageContentData: any;
  _nextI18Next: any;
}

const Technology = (props: Props) => {
  const { pageContentData, _nextI18Next: i18n } = props;
  const { pageContent = {}, shareContent = {} } = pageContentData || {};
  const { videoTitle, videoUrl, videoPoster, poster, videoPlayText } = pageContent;
  const UAVData = pageContent.UAVData as DataType;
  const autoCarData = pageContent.autoCarData as DataType;
  const manMachineData = pageContent.manMachineData as DataType;
  const retailData = pageContent.retailData as DataType;
  const scientificData = pageContent.scientificData as DataType;

  // TODO 暂时采用json获取方案
  // const { t } = useTranslation('technology');
  // const videoTitle = t('videoTitle');
  // const videoUrl = t('videoUrl');
  // const videoPoster = t('videoPoster');
  // const newsTextData: DataType = t('newsTextData', { returnObjects: true });
  // const UAVData: DataType = t('UAVData', { returnObjects: true });
  // const autoCarData: DataType = t('autoCarData', { returnObjects: true });
  // const manMachineData: DataType = t('manMachineData', { returnObjects: true });
  // const retailData: DataType = t('retailData', { returnObjects: true });
  // const scientificData: DataType = t('scientificData', { returnObjects: true });
  return (
    <Page title={shareContent.shareTitle}>
      <div className="bg-white">
        {/* 顶部视频模块 */}
        <VideoTopBanner
          videoPlayText={videoPlayText}
          videoTitle={videoTitle}
          videoLink={videoUrl}
          videoCoverImg={videoPoster}
          poster={poster}
          loop={false}
        />
        {/* 我们的科技内容模块 */}
        <TechnologyConetnt
          UAVData={UAVData}
          autoCarData={autoCarData}
          manMachineData={manMachineData}
          retailData={retailData}
          scientificData={scientificData}
        />
        {/* 底部新闻模块 */}
        <div className="pt-5 lg:px-5">
          <NewsFooter category={4} lanType={i18n.initialLocale} positionNo="msgcenter_4" />
        </div>
      </div>
    </Page>
  );
};

// 设置页面风格
// Technology.theme = 'dark';
export async function getServerSideProps({ locale, query }: any) {
  const pageContentParams = {
    uri: '/technology',
    pageContentNo: query.pageContentNo,
    preview: query.preview,
  };
  const pageContentData = await getPageContent(pageContentParams, locale);
  return {
    props: {
      lxCustom: {
        cid: 'c_donation_z52ltock',
        locale,
        ...pageContentData?.shareContent,
      },
      pageContentData,
      ...genShareConfig(pageContentData),
      ...(await serverSideTranslation(locale, ['technology', 'cookie', 'common'])),
    },
  };
}

export default Technology;
