import React from 'react';
import { useRouter } from 'next/router';

const Comma = () => {
  const router = useRouter();
  const { locale = 'zh-CN' } = router;
  const commaMap: { [key: string]: string } = {
    'zh-CN': '，',
    'zh-HK': '，',
    'en-US': ',',
  };

  return <span>{commaMap[locale]}</span>;
};

Comma.displayName = 'Comma';
export default Comma;
