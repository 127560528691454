// TechnologyConetnt

import React, { FC, useCallback } from 'react';
import classNames from 'classnames';

import styles from './TechnologyConetnt.module.scss';
import VideoPlayer from '../../components/VideoPlayer';
import { Image } from '../../components/Image';
import Comma from '../../components/Comma';

export interface DataType {
  [key: string]: string;
}

interface Props {
  UAVData: DataType;
  autoCarData: DataType;
  manMachineData: DataType;
  retailData: DataType;
  scientificData: DataType;
}
const TechnologyConetnt: FC<Props> = (props) => {
  const { UAVData, autoCarData, manMachineData, retailData, scientificData } = props;
  const autocarImg = 'https://p0.meituan.net/smartvenus/22f27c3cdd8f2701ef246055b9b98b53235950.png';

  // todo pm要求一直播放，不允许暂停，现用hack处理办法，如果暂停就业务主动在触发继续播放
  const onPause = useCallback((player: any) => {
    player?.play();
  }, []);

  return (
    <section className={styles['technology-conetnt-box']}>
      <div className={styles['technology-conetnt-uav-car']}>
        {/* 无人机相关模块 */}
        <div className={classNames(styles['content-item-box'])}>
          <div
            className={classNames(
              styles['content-item-wrap'],
              styles['technoloy-uav-content-box'],
              styles['technoloy-content-box'],
            )}
          >
            {/* 无人机标题 */}
            <div className={styles['uav-content-main-box']}>
              <div className={classNames(styles['content-item-title'], styles['content-item-title-limit'])}>
                <div>{UAVData.titleMain}</div>
                <div>{UAVData.titleVice}</div>
              </div>
              {/* 无人机介绍信息 */}
              <div className={classNames(styles['content-item-intro'], styles['content-item-intro-limit'])}>
                {UAVData.intro}
              </div>
              {/* 无人机相关数据 */}
              <div className={styles['content-item-data-box']}>
                <div className={styles['item-data-left']}>{UAVData.patentFTitle}</div>
                <div className={classNames('mt-font', styles['item-data-mid'])}>{UAVData.patentNum}</div>
                <div className={styles['item-data-right']}>{UAVData.PatentETitle}</div>
              </div>
              <div className={styles['content-item-intro-sm']}>{UAVData.patentIntro}</div>
              <div className={styles['content-item-data-box']}>
                <div className={styles['item-data-left']}>{UAVData.orderFTitle}</div>
                <div className={classNames('mt-font', styles['item-data-mid'])}>
                  <span className={styles['mid-order-quantity']}>{UAVData.orderQuantity}</span>
                  <span className={styles['mid-order-unit']}>{UAVData.orderUnit}</span>
                </div>
                <div className={styles['item-data-right']}>{UAVData.orderETitle}</div>
              </div>
              <div className={styles['content-item-intro-sm']}>{UAVData.orderIntro}</div>
            </div>
            <div className={styles['uav-img-box']}>
              <Image src={UAVData.bgImg} width={848} height={445} layout="responsive" />
            </div>
            {/* 无人机相关链接 */}
            {/* <div className={styles['item-link-box']}>
            <div className={styles['item-link']}>{UAVData.linkText}</div>
            <div className={styles['item-arrow']} />
          </div> */}
          </div>
        </div>

        {/* 自动车相关模块 */}
        <div className={classNames(styles.autocarbox, styles['content-item-box'])}>
          <div
            className={classNames(styles['content-item-wrap'], styles['technoloy-content-box'], styles['auto-car-box'])}
          >
            <div className={styles['auto-car-content']}>
              {/* 自动车标题 */}
              <div className={classNames(styles['content-item-title'], styles['content-item-title-limit'])}>
                <div>{autoCarData.titleMain}</div>
                <div>{autoCarData.titleVice}</div>
              </div>
              {/* 自动车介绍信息 */}
              <div className={classNames(styles['content-item-intro'], styles['content-item-intro-limit'])}>
                {autoCarData.intro}
              </div>
              {/* 自动车相关数据 */}
              <div className={styles['content-item-data-box']}>
                <div className={classNames('mt-num-font', styles['item-data-mid'])}>{autoCarData.autoCarRange}</div>
                <div className={styles['item-divide-line']} />
                <div className={classNames('mt-num-font', styles['item-data-mid'])}>{autoCarData.autoCarLength}</div>
              </div>
              <div className={styles['content-item-intro-sm']}>{autoCarData.rangeText}</div>
              <div className={styles['content-item-data-box']}>
                <div className={classNames('mt-num-font', styles['item-data-mid'])}>
                  {autoCarData.autoCarPerception}
                </div>
              </div>
              <div className={styles['content-item-intro-sm']}> {autoCarData.perceptionText}</div>

              {/* 自动车相关链接 */}
              {/* <a className={styles['item-link-box']} target="_blank" href={autoCarData.linkUrl} rel="noopener noreferrer">
              <div className={styles['item-link']}>{UAVData.linkText}</div>
              <div className={styles['item-arrow']} />
            </a> */}
            </div>
            <div className={styles['auto-car-img-box']}>
              <Image src={autocarImg} width={1754} height={934} layout="responsive" />
            </div>
          </div>
        </div>
      </div>

      {/* 人机城相关模块 */}
      <div className="px-5 pt-[50px] lg:pt-[100px]">
        <div className={styles['content-item-wrap']}>
          {/* 人机城标题 */}
          <div className={styles['content-item-title']}>
            <div className={styles['content-item-title-item']}>{manMachineData.titleMain}</div>
            {manMachineData.titleVice && (
              <span className={styles['comma-box']}>
                <Comma />
              </span>
            )}

            <div className={styles['content-item-title-item']}>{manMachineData.titleVice}</div>
          </div>
          {/* 人机城介绍信息 */}
          <div className={styles['content-item-intro']}>
            <p>{manMachineData.intro1}</p>
            <p>{manMachineData.intro2}</p>
          </div>

          {/* 人机城相关链接 */}
          {/* <div className={styles['item-link-box']}>
            <div className={styles['item-link']}>{manMachineData.linkText}</div>
            <div className={styles['item-arrow']} />
          </div> */}

          {/* 人机城相关视频 */}
          <div className={styles['video-box']}>
            <div className={styles['video-wrap']}>
              <VideoPlayer
                onPause={onPause}
                source={manMachineData.relevantVideo1}
                poster={manMachineData.relevantPoster1}
                autoplay
                hideControl
              />
            </div>
            <div className={styles['video-wrap']}>
              <VideoPlayer
                onPause={onPause}
                source={manMachineData.relevantVideo2}
                poster={manMachineData.relevantPoster2}
                autoplay
                hideControl
              />
            </div>
          </div>
        </div>
      </div>

      {/* 零售场景模块 */}
      <div className="px-5 pt-[50px] lg:pt-[100px]">
        <div className={styles['content-item-wrap']}>
          {/* 零售标题 */}
          <div className={styles['content-item-title']}>
            <div className={styles['content-item-title-item']}>{retailData.titleMain}</div>

            <div className={styles['content-item-title-item']}>{retailData.titleVice}</div>
          </div>
          {/* 零售介绍信息 */}
          <div className={styles['content-item-intro']}>
            <span>{retailData.intro}</span>
          </div>

          {/* 人机城相关链接 */}
          {/* <div className={styles['item-link-box']}>
            <div className={styles['item-link']}>{retailData.linkText}</div>
            <div className={styles['item-arrow']} />
          </div> */}

          {/* 人机城相关视频 */}
          <div className={styles['video-box']}>
            <div className={styles['video-wrap']}>
              <VideoPlayer
                onPause={onPause}
                source={retailData.relevantVideo1}
                poster={retailData.relevantPoster1}
                autoplay
                hideControl
              />
            </div>
            <div className={styles['video-wrap']}>
              <VideoPlayer
                onPause={onPause}
                source={retailData.relevantVideo2}
                poster={retailData.relevantPoster2}
                autoplay
                hideControl
              />
            </div>
          </div>
        </div>
      </div>

      {/* 科研合作相关模块 */}
      <div className="px-5 pt-[50px] lg:pt-[100px]">
        <div className={styles['content-item-wrap']}>
          {/* 科研合作标题 */}
          <div className={styles['content-item-title']}>
            <div className={styles['content-item-title-item']}>{scientificData.titleMain}</div>
            {scientificData.titleVice && (
              <span className={styles['comma-box']}>
                <Comma />
              </span>
            )}
            <div className={styles['content-item-title-item']}>{scientificData.titleVice}</div>
          </div>
          {/* 科研合作介绍信息 */}
          <div className={styles['content-item-intro']}>
            <span>{scientificData.intro1}</span>
            <span>{scientificData.intro2}</span>
          </div>
          <div className="relative h-[40vw] max-h-[354px] overflow-hidden  rounded-lg lg:rounded-2xl">
            <Image src={scientificData.relevantImg} layout="fill" objectFit="cover" compressOptions={{ quality: 80 }} />
          </div>
        </div>
      </div>
    </section>
  );
};
TechnologyConetnt.defaultProps = {};

TechnologyConetnt.displayName = 'TechnologyConetnt';
export default TechnologyConetnt;
