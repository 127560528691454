import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import styles from './VideoTopBanner.module.scss';
import VideoPlayer from '../../components/VideoPlayer';

interface Props {
  videoCoverImg?: string;
  videoLink: string;
  isOpen: boolean;
  loop?: boolean;
  closeModal(): void;
}

export const VideoModal = (props: Props) => {
  const { videoCoverImg, videoLink, isOpen, closeModal, loop } = props;
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className={styles['modal-mask']} onClose={closeModal}>
        {/* 模态框遮罩 */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000] bg-opacity-100 lg:bg-opacity-70" />
        </Transition.Child>

        <div className={classNames(styles['close-btn'], styles['sm-close-btn'])} />

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all lg:w-55.62r">
                <div className={classNames(styles['close-btn'], styles['lg-close-btn'])} onClick={closeModal} />
                <div className="w-full">
                  <VideoPlayer poster={videoCoverImg} source={videoLink} autoplay loop={loop} showBigButton />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
