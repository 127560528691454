import React, { FC, useState } from 'react';

import styles from './VideoTopBanner.module.scss';
import { VideoModal } from './Modal';
import { Image } from '../../components/Image';

interface Props {
  videoTitle: string | string[];
  videoLink?: string;
  videoCoverImg: string;
  videoPlayText?: string;
  videoTitleStyle?: Record<string, any>;
  loop?: boolean;
  poster?: string;
}
const VideoTopBanner: FC<Props> = (props) => {
  const { videoTitle, videoLink, videoCoverImg, videoPlayText = '观看视频', videoTitleStyle, loop, poster } = props;
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  const hasVideo = !!videoLink;

  return (
    <section className={styles['video-banner-box']}>
      <div className={styles['coverimg-box']}>
        <Image src={videoCoverImg} compressOptions={{ quality: 60 }} layout="fill" objectFit="cover" />
      </div>
      <div className={styles['video-content']}>
        <div
          style={videoTitleStyle}
          className={`${styles['video-title']} ${hasVideo ? null : styles['video-title-no']} mt-font`}
        >
          {typeof videoTitle === 'string' ? (
            <span>{videoTitle}</span>
          ) : (
            videoTitle?.map((title, i) => <span key={i}>{title}</span>)
          )}
        </div>
        {hasVideo && (
          <div className={styles['video-play-box']} onClick={openModal}>
            <div className={styles['video-play-btn']}>{/* <div className={styles['video-play-arrow']} /> */}</div>
            <div className={styles['video-play-text']}>{videoPlayText}</div>
          </div>
        )}
      </div>

      {/* 视频模态框 */}
      {hasVideo && (
        <VideoModal videoCoverImg={poster} isOpen={isOpen} closeModal={closeModal} videoLink={videoLink} loop={loop} />
      )}
    </section>
  );
};

VideoTopBanner.displayName = 'VideoTopBanner';
export default VideoTopBanner;
